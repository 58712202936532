import Papa from 'papaparse';
import * as TE from 'fp-ts/lib/TaskEither';
import { generateError, UserUploadError } from '../../types/errors';
import { CSV_ROW_LIMIT, CSV_REQUIRED_FIELDS } from '../constants';
import { NewUser, ParsedCsvData, CsvDataForDisplay } from '../../types/data';
import { isEmailValid } from '../isEmailValid';

/**
 * checkFileExtension
 * - Ensures the file extension is .csv
 *
 * *args* - file - A file
 *
 * *return val* - The file or an error if file extension is invalid.
 */
// export const checkFileExtension = (file: File): E.Either<UserUploadError, File> => {
export const checkFileExtension = (
  file: File
): TE.TaskEither<UserUploadError, File> => {
  return file.type !== 'text/csv'
    ? TE.left(generateError({ type: 'InvalidFileType' }))
    : TE.right(file);
};

/**
 * containsUnacceptableErrors
 * - Returns a boolean indicating if unacceptable fields are present.
 *
 * *args* - providedErrCodes - Array<string> - An array of provided error codes recieved after parsing.
 */
export const containsUnacceptableErrors = (
  providedErrCodes: Array<string>
): boolean => {
  const acceptableErrCodes = ['TooFewFields', 'TooManyFields'];

  return providedErrCodes.some((providedErrCode) => {
    return !acceptableErrCodes.includes(providedErrCode);
  });
};

/**
 * caughtParseCsv
 * *args* - file - A file
 *
 * *return val* Returns a CSV Upload Error or the parsed csv data
 */
export const caughtParseCsv = (
  file: File
): TE.TaskEither<UserUploadError, ParsedCsvData> => {
  return TE.tryCatch(
    async () => parseCsv(file) as Promise<ParsedCsvData>,
    (err) => err as UserUploadError
  );
};

/**
 * parseCsv
 * - Converts the csv file to a js object
 *
 * *args* - file - A file
 *
 * *return val* - Returns a Promise of the response from parsing the CSV.
 */
export const parseCsv = (
  file: File
): Promise<UserUploadError | ParsedCsvData> => {
  return new Promise((resolve, reject) => {
    const parsingError = generateError({ type: 'CsvFailedToParse' });

    Papa.parse(file, {
      transform: (value) => value.trim(),
      transformHeader: (header) => header.trim(),
      header: true,
      delimiter: ',',
      newline: '\n',
      skipEmptyLines: true,
      dynamicTyping: true,
      complete: (result) => {
        const errorCodes = result.errors.map((err) => err.code);

        containsUnacceptableErrors(errorCodes)
          ? reject(parsingError)
          : resolve(result as ParsedCsvData);
      },
    });
  });
};

/**
 * checkNoRows
 * - Ensures the number of rows in the csv is greater than 0
 *
 * *args* - parsedData - an object containing the row data, meta info about the file, and any parsing errors.
 *
 * *return val* - Either the parsedData or an error if invalid number of rows in the data field.
 */
export const checkNoRows = (
  parsedData: ParsedCsvData
): TE.TaskEither<UserUploadError, ParsedCsvData> => {
  return parsedData.data.length < 1
    ? TE.left(generateError({ type: 'NoRowsInCsv' }))
    : TE.right(parsedData);
};

/**
 * checkTooManyRows
 * - Ensures the number of rows in the csv is less than designated row limit
 *
 * *args* - parsedData - an object containing the row data, meta info about the file, and any parsing errors.
 *
 * *return val* - Either the parsedData or an error if invalid number of rows in the data field.
 */
export const checkTooManyRows = (
  parsedData: ParsedCsvData
): TE.TaskEither<UserUploadError, ParsedCsvData> => {
  return parsedData.data.length > CSV_ROW_LIMIT
    ? TE.left(generateError({ type: 'TooManyRowsInCsv' }))
    : TE.right(parsedData);
};

/**
 * checkFieldNames
 * - Ensures required fields exist.
 *
 * *args* - parsedData - an object containing the row data, meta info about the file, and any parsing errors.
 *
 * *return val* - Either the parsedData or an error if invalid field names
 */
export const checkFieldNames = (
  parsedData: ParsedCsvData
): TE.TaskEither<UserUploadError, ParsedCsvData> => {
  const providedFields = parsedData.meta.fields;
  const requiredFields = CSV_REQUIRED_FIELDS;

  const missingRequiredFields = requiredFields.filter(
    (requiredField) => !providedFields.includes(requiredField)
  );

  const missingRequiredFieldErr = generateError({
    type: 'MissingRequiredField',
    extraParams: { missingFields: missingRequiredFields },
  });

  return missingRequiredFields.length > 0
    ? TE.left(missingRequiredFieldErr)
    : TE.right(parsedData);
};

/**
 * checkInvalidEmails
 * - Removes rows with invalidly formatted emails, be they the user's email
 * or their manager's email
 *
 * *args* - newUsers - Array<NewUsers> - Array of users
 *
 * *return val* - Array<NewUsers> - Array of users with valid emails
 */
export const checkInvalidEmails = (
  newUsers: Array<NewUser>
): Array<NewUser> => {
  const filteredUsers = newUsers.filter((user) => isEmailValid(user.email));

  return filteredUsers;
};

/**
 * checkDuplicateEmails
 * Removes records containing duplicate emails
 *
 * *args* - newUsers - Array<NewUsers> - The array of new users
 *
 * *return val* - newUsers - Array<NewUsers> - The array of new users with
 * those having duplicate emails removed
 */
export const checkDuplicateEmails = (
  newUsers: Array<NewUser>
): Array<NewUser> => {
  const userMap: Map<string, NewUser> = new Map();

  const dedupedUserMap = newUsers.reduce((userMap, user) => {
    !userMap.get(user.email) && userMap.set(user.email, user);

    return userMap;
  }, userMap);

  return [...dedupedUserMap].map(([_, value]) => value);
};

interface PackageCsvDataArgs {
  newUsers: Array<NewUser>;
  fileName: string;
}

export const packageCsvData = ({
  newUsers,
  fileName,
}: PackageCsvDataArgs): CsvDataForDisplay => ({
  usersList: newUsers,
  fileName,
});

/**
 * getUsersFromCsvData
 * - pulls users from parsed csv data
 */
export const getUsersFromCsvData = (
  parsedCsvData: ParsedCsvData
): Array<NewUser> => parsedCsvData.data;
