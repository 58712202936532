import { useParams } from 'react-router-dom';
import { config } from '../config';

export const useDashboardNavigateUrl = () => {
  const { orgSlug } = useParams<'orgSlug'>();
  const { dashboardUrl } = config;
  const dashboardNavigateUrl = `${dashboardUrl}/${orgSlug}/settings/users`;

  return dashboardNavigateUrl;
};
