import { useTranslation } from 'react-i18next';
import { HandleInputChange, HandleEmailBlur } from '../types';
import FormInput from './FormInput';
import { FlexColumnContainer } from '../../../sharedComponents/sharedStyledComponents';
import { EmailError } from './styles';

interface Props {
  inputVal: string;
  handleInputChange: HandleInputChange;
  handleEmailInputChange: HandleInputChange;
  handleEmailBlur: HandleEmailBlur;
  rowIdx: number;
  showError: boolean;
}

const EmailInput = ({
  inputVal,
  handleEmailInputChange,
  handleEmailBlur,
  rowIdx,
  showError,
}: Props): JSX.Element => {
  const { t } = useTranslation('manual_upload');

  return (
    <FlexColumnContainer>
      <FormInput
        inputName="email"
        inputVal={inputVal}
        handleInputChange={handleEmailInputChange}
        handleBlur={() => handleEmailBlur({ rowIdx, inputVal })}
        rowIdx={rowIdx}
      />
      {showError && <EmailError>{t('form.invalid_email_error')}</EmailError>}
    </FlexColumnContainer>
  );
};

export default EmailInput;
