import { Button, Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';

const getColor = ({ disabled }: { disabled?: boolean }) =>
  disabled ? Colors.Gray40 : Colors.White0;

const CancelButton = styled(Button)`
  color: ${getColor};
  margin-right: 20px;

  &:hover {
    background: ${Colors.Gray80};
  }
`;

export default CancelButton;
