import { Heading05, Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';

export const CsvUploadInfoContainer = styled.div`
  background: ${Colors.Tan70};
  border-radius: 16px;
  padding: 24px;
`;

export const CsvUploadInfoHeading = styled(Heading05)`
  margin-bottom: 8px;
`;
