import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Global } from '@emotion/react';
import { RKTheme } from '@robinpowered/design-system';
import { cssReset } from './cssReset';
import {
  ApolloContextProvider,
  AuthContextProvider,
  ToastProvider,
  useAuthContext,
} from 'contexts';
import { GlobalErrorBoundary } from 'components/GlobalErrorBoundary';
import RedirectToDashboardHomepage from 'app/pages/RedirectToDashboardHomepage';
import CsvUploadFlow from 'app/pages/CsvUploadFlow';
import ManualUpload from 'app/pages/ManualUpload';
import { PageMessage, RobinLoader } from 'components';
import { useEffect } from 'react';
import { useGainsight, useUserAccess } from 'hooks';
import { config } from './config';
import { i18n } from 'i18n';

const NODE_ENV = config.env;

export const App = (): JSX.Element => {
  return (
    <I18nextProvider i18n={i18n}>
      <GlobalErrorBoundary>
        <Global styles={cssReset} />
        <RKTheme>
          <BrowserRouter>
            <ApolloContextProvider>
              <AuthContextProvider>
                <HelmetProvider>
                  <DocumentHead />
                  <RootRouteTree />
                </HelmetProvider>
              </AuthContextProvider>
            </ApolloContextProvider>
          </BrowserRouter>
        </RKTheme>
      </GlobalErrorBoundary>
    </I18nextProvider>
  );
};

const RootRouteTree = (): JSX.Element => {
  const { t } = useTranslation('error');
  return (
    <Routes>
      <Route path="/" element={<PageMessage title={t('missing_slug')} />} />
      <Route path="*" element={<RedirectToDashboardHomepage />} />
      <Route path=":orgSlug/user-upload/*" element={<AllRoutes />} />
    </Routes>
  );
};

const AllRoutes = (): JSX.Element => {
  const { t } = useTranslation('error');
  const { orgSlug } = useParams<'orgSlug'>();

  const { loading: authLoading, setOrgSlug, isLoggedIn } = useAuthContext();
  const { loading: userAccessLoading } = useUserAccess();
  const loading = authLoading || userAccessLoading;

  useGainsight();

  useEffect(() => {
    setOrgSlug(orgSlug);
  }, [orgSlug, setOrgSlug]);

  if (loading) {
    return <RobinLoader />;
  }

  let error;
  if (!isLoggedIn) {
    error = t('unauthenticated');
  }

  if (error) {
    return NODE_ENV === 'development' ? (
      <PageMessage title={error} />
    ) : (
      <RedirectToDashboardHomepage />
    );
  }

  return (
    <ToastProvider>
      <Routes>
        <Route path="*" element={<RedirectToDashboardHomepage />} />
        <Route path="with-csv" element={<CsvUploadFlow />} />
        <Route path="manual" element={<ManualUpload />} />
      </Routes>
    </ToastProvider>
  );
};

export const DocumentHead = (): JSX.Element => {
  const { t } = useTranslation('common');
  return (
    <Helmet>
      <title>{t('default_page_title')}</title>
    </Helmet>
  );
};
