import { useState, useEffect } from 'react';
import * as E from 'fp-ts/lib/Either';
import { useTranslation } from 'react-i18next';
import UsersListUi from './components/UsersListUi';
import { NewUserDisplayData } from '../../types';
import User from '../../../../api/User';
import { HandleAddPeopleArgs } from '../../types';
import { useApolloContext } from 'contexts';
import { useAccessToken, useDashboardNavigateUrl } from 'hooks';
import { useToast } from 'contexts/ToastContext';

export interface State {
  usersList: Array<NewUserDisplayData>;
  sendEmailInvite: boolean;
  formSubmitted: boolean;
}
interface Props {
  usersList: Array<NewUserDisplayData>;
  handleExit: () => void;
  goToPreviousView: () => void;
  csvFile: File | null;
}

/**
 * UsersListContainer
 * - Houses UI logic and state for the Users List step
 *
 * - On initial page render, renders first subset of usersList only
 * - After initial page render, renders full usersList
 * - This improves performance of initial page load on large sets of users
 *
 * - For extremely large CSV file sizes (3000+ rows) a solution like
 * react-virtualized can be an option to render usersList while maintaining browser performance
 * https://github.com/bvaughn/react-virtualized
 */
const UsersListContainer = (props: Props): JSX.Element => {
  const { tenantId: organizationId } = useApolloContext();
  const apiKey = useAccessToken();
  const dashboardNavigateUrl = useDashboardNavigateUrl();
  const toast = useToast();
  const { t } = useTranslation('error');

  const usersListSubset = props.usersList.slice(0, 50);

  const initialState: State = {
    usersList: usersListSubset,
    sendEmailInvite: true,
    formSubmitted: false,
  };

  const [state, setState] = useState(initialState);

  /**
   * After page load renders full list of users
   */
  useEffect(() => {
    setTimeout(
      () => setState((state) => ({ ...state, usersList: props.usersList })),
      0
    );
  }, [props.usersList]);

  const handleSendEmailToggle = () =>
    setState((state) => ({
      ...state,
      sendEmailInvite: !state.sendEmailInvite,
    }));

  /**
   * handleAddPeople
   * - Triggers api call to create new users in Robin via a CSV upload
   */
  const handleAddPeople = async ({ sendEmailInvite }: HandleAddPeopleArgs) => {
    setState({
      ...state,
      formSubmitted: true,
    });

    const submitCsvResult = await User.addUsersWithCsv({
      csvFile: props.csvFile as File,
      numOfRows: state.usersList.length,
      sendEmailInvite,
      organizationId,
      apiKey,
    });

    const handleFailedSubmit = () => {
      toast.error(t('add_people_failed'));
      setState({
        ...state,
        formSubmitted: false,
      });
    };

    E.isRight(submitCsvResult)
      ? window.location.assign(
          `${dashboardNavigateUrl}?addPeopleStatus=inProgress`
        )
      : handleFailedSubmit();
  };

  return (
    <UsersListUi
      usersList={state.usersList}
      handleExit={props.handleExit}
      goToPreviousView={props.goToPreviousView}
      handleSendEmailToggle={handleSendEmailToggle}
      handleAddPeople={handleAddPeople}
      sendEmailInvite={state.sendEmailInvite}
      formSubmitted={state.formSubmitted}
    />
  );
};

export default UsersListContainer;
