import { UserUploadError } from '../../../../../types/errors';
import { CSV_ROW_LIMIT } from '../../../../../utils/constants';
import { printList } from '../../../../../utils';

export const getUserErrorMessaging = (errorObj: UserUploadError): string => {
  const errorType = errorObj.__type;

  switch (errorType) {
    case 'InvalidFileType':
      return 'The selected file must be a CSV';

    case 'CsvFailedToParse':
      return 'Invalid CSV format. Check the formatting, then try again.';

    case 'NoRowsInCsv':
      return 'The selected file is empty';

    case 'TooManyRowsInCsv':
      return `The selected file must contain less than ${CSV_ROW_LIMIT} people`;

    case 'MissingRequiredField':
      return `Missing required header fields in your CSV: ${printList(
        errorObj.missingFields
      )}`;

    default:
      return 'There was an issue uploading your CSV. Please reach out to Robin for help.';
  }
};
