import { useState } from 'react';
import * as E from 'fp-ts/lib/Either';
import clone from 'clone';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useApolloContext } from 'contexts';
import { useAccessToken, useDashboardNavigateUrl } from 'hooks';
import ManuallUploadUi from './components/ManuallUploadUi';
import {
  PeopleTableRow,
  HandleInputChangeArgs,
  HandleEmailBlurArgs,
  HandleAddPeopleArgs,
} from './types';
import { isEmailValid } from '../../utils/isEmailValid';
import User from '../../api/User';
import convertFormDataToApiData from './utils/convertFormDataToApiData';
import { useToast } from 'contexts/ToastContext';

export interface State {
  peopleRows: Array<PeopleTableRow>;
  sendEmailInvite: boolean;
  formSubmitted: boolean;
}

/**
 * ManualUploadContainer
 * - handles UI logic and state for the Manual Upload flow
 */
const ManualUploadContainer = (): JSX.Element => {
  const { tenantId: organizationId } = useApolloContext();
  const apiKey = useAccessToken();
  const dashboardNavigateUrl = useDashboardNavigateUrl();
  const toast = useToast();
  const { t } = useTranslation('error');

  /**
   * generateEmptyPeopleRow
   * - generates data for an empty form row
   * - populates a unique row id for key props for React node list items
   */
  const generateEmptyPeopleRow = (): PeopleTableRow => ({
    email: '',
    fullName: '',
    showEmailError: false,
    rowId: uuidv4(),
  });

  const initialState: State = {
    peopleRows: [generateEmptyPeopleRow()],
    sendEmailInvite: true,
    formSubmitted: false,
  };

  const [state, setState] = useState(initialState);

  /**
   * handleAddPeople
   * - triggers api call to create new users
   */
  const handleAddPeople = async ({ sendEmailInvite }: HandleAddPeopleArgs) => {
    setState({
      ...state,
      formSubmitted: true,
    });

    const newUsers = convertFormDataToApiData(state.peopleRows);

    const addUsersResult = await User.addUsersManually({
      newUsers,
      sendEmailInvite,
      organizationId,
      apiKey,
    });

    const handleFailedSubmit = () => {
      toast.error(t('add_people_failed'));
      setState({
        ...state,
        formSubmitted: false,
      });
    };

    E.isRight(addUsersResult)
      ? window.location.assign(
          `${dashboardNavigateUrl}?addPeopleStatus=peopleAdded`
        )
      : handleFailedSubmit();
  };

  const addFormRow = () => {
    setState((state) => ({
      ...state,
      peopleRows: [...state.peopleRows, generateEmptyPeopleRow()],
    }));
  };

  /**
   * handleInputChange
   * - triggers change of any form inputs other than email
   */
  const handleInputChange = ({ event, rowIdx }: HandleInputChangeArgs) => {
    const { value, name } = event.target;

    setState((state) => {
      const peopleRowsClone = clone(state.peopleRows);

      const updatedRow = {
        ...state.peopleRows[rowIdx],
        [name]: value,
      };

      peopleRowsClone[rowIdx] = updatedRow;

      return { ...state, peopleRows: peopleRowsClone };
    });
  };

  /**
   * handleEmailInputChange
   * - triggers change of email inputs
   * - if in the process of typing email becomes valid then remove error
   */
  const handleEmailInputChange = ({ event, rowIdx }: HandleInputChangeArgs) => {
    const emailVal = event.target.value.trim();
    const removeEmailError = isEmailValid(emailVal);

    setState((state) => {
      const peopleRowsClone = clone(state.peopleRows);

      const updatedRow = {
        ...state.peopleRows[rowIdx],
        email: emailVal,
        ...(removeEmailError && { showEmailError: false }),
      };

      peopleRowsClone[rowIdx] = updatedRow;

      return { ...state, peopleRows: peopleRowsClone };
    });
  };

  /**
   * handleEmailBlur
   * - if email is invalid when focus is removed from the input then show error
   */
  const handleEmailBlur = ({ inputVal, rowIdx }: HandleEmailBlurArgs) => {
    setState((state) => {
      const peopleRowsClone = clone(state.peopleRows);

      const updatedRow = {
        ...state.peopleRows[rowIdx],
        showEmailError: !isEmailValid(inputVal),
      };

      peopleRowsClone[rowIdx] = updatedRow;

      return { ...state, peopleRows: peopleRowsClone };
    });
  };

  const removeRow = (rowIdx: number) => {
    setState((state) => {
      const peopleRowsClone = clone(state.peopleRows);
      peopleRowsClone.splice(rowIdx, 1);

      return { ...state, peopleRows: peopleRowsClone };
    });
  };

  const toggleEmailInvite = () =>
    setState((state) => ({
      ...state,
      sendEmailInvite: !state.sendEmailInvite,
    }));

  const handleExit = () => window.location.assign(dashboardNavigateUrl);

  /**
   * isAddPeopleEnabled
   * - *args* - peopleRows - row of people data
   * - *return val* boolean indicating if user is allowed to add people
   * - Can add people if
   * -- there is at least 1 row with a valid email
   * -- and all rows either have valid emails or are empty rows
   */
  const isAddPeopleEnabled = (peopleRows: Array<PeopleTableRow>) => {
    const rowIsEmpty = (row: PeopleTableRow) =>
      row.email === '' && row.fullName === '';

    return (
      peopleRows.some((row) => isEmailValid(row.email)) &&
      peopleRows.every((row) => isEmailValid(row.email) || rowIsEmpty(row))
    );
  };

  return (
    <ManuallUploadUi
      peopleRows={state.peopleRows}
      handleAddPeople={handleAddPeople}
      addFormRow={addFormRow}
      handleInputChange={handleInputChange}
      handleEmailInputChange={handleEmailInputChange}
      handleEmailBlur={handleEmailBlur}
      removeRow={removeRow}
      sendEmailInvite={state.sendEmailInvite}
      toggleEmailInvite={toggleEmailInvite}
      handleExit={handleExit}
      enableAddPeopleButtons={isAddPeopleEnabled(state.peopleRows)}
      formSubmitted={state.formSubmitted}
    />
  );
};

export default ManualUploadContainer;
