import { useQuery, gql, ApolloError } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  UserAccessForUploadPeopleAppQuery,
  UserAccessForUploadPeopleAppQueryVariables,
} from 'generated';

const USER_ACCESS_QUERY = gql`
  query UserAccessForUploadPeopleApp {
    getCurrentUserAndOrgs {
      userId
      hasPlacesManageForCurrentTenant
    }
  }
`;

type UseUserAccess = {
  loading: boolean;
  error?: ApolloError;
  canManagePlaces: boolean;
};

/**
 * Returns whether the user has the ability to manage places.
 *
 * This must be called separately from our auth query with the same `getCurrentUserAndOrgs`,
 * since we need the tenant ID set to get these permissions.
 */
export const useUserAccess = (): UseUserAccess => {
  const { tenantId } = useApolloContext();
  const { loading, error, data } = useQuery<
    UserAccessForUploadPeopleAppQuery,
    UserAccessForUploadPeopleAppQueryVariables
  >(USER_ACCESS_QUERY, {
    skip: !tenantId,
  });

  const canManagePlaces =
    !!data?.getCurrentUserAndOrgs?.hasPlacesManageForCurrentTenant;

  return {
    loading,
    error,
    canManagePlaces,
  };
};
