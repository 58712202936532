import { useTranslation } from 'react-i18next';
import { Toggle, Body06, Body05, Link } from '@robinpowered/design-system';
import {
  InfoBox,
  InfoBoxContent,
  EmailInvitesToggleRow,
  StyledInfoIconComp,
} from './styles';

interface Props {
  sendEmailInvite: boolean;
  toggleEmailInvite: () => void;
}

const EmailInvitesToggle = ({
  sendEmailInvite,
  toggleEmailInvite,
}: Props): JSX.Element => {
  const { t } = useTranslation('manual_upload');
  const sendInvitesCopy = t('email_invites_card.send_invites_desc');
  const dontSendInvitesCopy = t('email_invites_card.dont_send_invites_desc');

  return (
    <div>
      <EmailInvitesToggleRow>
        <Body05>
          <label id="sendEmailInvites">
            {t('email_invites_card.toggle_desc')}
          </label>
        </Body05>
        <Toggle
          checked={sendEmailInvite}
          onChange={toggleEmailInvite}
          ariaLabelledBy="sendEmailInvites"
          small={true}
        />
      </EmailInvitesToggleRow>

      <InfoBox>
        <StyledInfoIconComp />
        <InfoBoxContent>
          <Body06>
            {sendEmailInvite ? sendInvitesCopy : dontSendInvitesCopy}
          </Body06>
          <Link
            href="https://support.robinpowered.com/hc/en-us/articles/203687424-Invite-your-coworkers"
            target="_blank"
          >
            {t('email_invites_card.learn_more_link')}
          </Link>
        </InfoBoxContent>
      </InfoBox>
    </div>
  );
};

export default EmailInvitesToggle;
