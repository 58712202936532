import { useTranslation } from 'react-i18next';
import { Button } from '@robinpowered/design-system';
import Header from '../../../../../sharedComponents/Header';
import CsvUploadInfo from '../../../sharedComponents/CsvUploadInfo/CsvUploadInfo';
import CsvUploadCard from '../../../sharedComponents/CsvUploadCard/CsvUploadCard';
import CancelButton from '../../../../../sharedComponents/CancelButton';
import {
  CsvDropBoxFlexContainer,
  CsvInfoFlexContainer,
  CsvConfirmFileBox,
  CsvFlexContainer,
} from '../../styles';

interface Props {
  goToNextView: () => void;
  handleRemoveCsvFile: () => void;
  handleExit: () => void;
  fileName: string | null;
}

const FileConfirmationUi = (props: Props): JSX.Element => {
  const { t } = useTranslation('csv_upload');

  return (
    <>
      <Header>
        <>
          <CancelButton
            variant="tertiary"
            size="default"
            onClick={props.handleExit}
          >
            {t('file_confirmation.header.cancel')}
          </CancelButton>

          <Button variant="primary" size="default" onClick={props.goToNextView}>
            {t('file_confirmation.header.next')}
          </Button>
        </>
      </Header>

      <CsvFlexContainer>
        <CsvDropBoxFlexContainer>
          <CsvUploadCard>
            <CsvConfirmFileBox>
              <span>{props.fileName}</span>
              <Button
                variant="tertiary"
                size="default"
                danger={true}
                onClick={props.handleRemoveCsvFile}
              >
                {t('file_confirmation.remove_file')}
              </Button>
            </CsvConfirmFileBox>
          </CsvUploadCard>
        </CsvDropBoxFlexContainer>

        <CsvInfoFlexContainer>
          <CsvUploadInfo />
        </CsvInfoFlexContainer>
      </CsvFlexContainer>
    </>
  );
};

export default FileConfirmationUi;
