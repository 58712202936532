import { PeopleTableRow } from '../types';
import { CreateUserDto } from '../../../types/data';

const convertFormDataToApiData = (
  formRowData: Array<PeopleTableRow>
): Array<CreateUserDto> => {
  return formRowData.map((row: PeopleTableRow) => ({
    email: row.email,
    name: row.fullName,
    department: null,
    title: null,
    manager: null,
  }));
};

export default convertFormDataToApiData;
