import styled from '@emotion/styled';
import {
  Table,
  Body05,
  Flex,
  Heading01,
  Button,
  Checkbox,
  Link,
  Colors,
} from '@robinpowered/design-system';

// FileConfirmationUI, UploadFormUi /////////////////////////////////
export const CsvFlexContainer = styled(Flex)`
  display: flex;
  justify-content: space-between;
`;

export const CsvDropBoxFlexContainer = styled.div`
  width: 65%;
  margin-left: 30px;
`;
export const CsvInfoFlexContainer = styled.div`
  width: 35%;
  margin-left: 30px;
  margin-right: 30px;
`;

export const CsvConfirmFileBox = styled.div`
  border: 1px solid ${Colors.Tan70};
  color: ${Colors.Gray80};
  border-radius: 16px;
  padding: 12px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// CsvDropBox //////////////////////////////////////////////////////
const DropBoxBorderColor = (props: {
  hovered: boolean;
  errorExists: boolean;
}) => {
  return props.hovered
    ? Colors.Maroon100
    : props.errorExists
    ? Colors.RedProduct
    : Colors.Tan70;
};

export const DropBox = styled.form`
  margin-bottom: 8px;
  border: 2px dashed ${DropBoxBorderColor};
  height: 200px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const DropBoxContent = styled(Body05)`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const FileInputLabel = styled.span`
  text-decoration: underline;
  display: inline;
  cursor: pointer;
`;

// UserListUi //////////////////////////////////////////////////////
export const BackButton = styled(Button)`
  background: ${Colors.Gray40};
  margin-right: 18px;

  &:hover {
    background: ${Colors.Gray80};
    color: ${Colors.Tan30};
  }
`;

export const SendEmailFlexContainer = styled(Flex)`
  width: 100%;
`;

export const UserListContainer = styled.div`
  padding: 20px;
  border: 1px solid ${Colors.Tan70};
  border-radius: 16px;
  margin-left: 28px;
  margin-right: 28px;
`;

export const FooterPadding = styled.span`
  height: 27px;
  display: block;
`;

export const UserListHeading = styled(Heading01)`
  align-self: flex-start;
  margin-bottom: 20px;
`;

export const UserListCheckbox = styled(Checkbox)`
  margin-right: 8px;
`;

export const UserListLearnMoreLink = styled(Link)`
  font-size: 16px;
  text-decoration: underline;
`;

// UserListTable ////////////////////////////////////////////////
export const UsersTableWrap = styled.div`
  position: relative;
  height: calc(90vh - 260px);
  margin-bottom: 30px;
  width: 100%;
  overflow: scroll;
  border: 1px solid ${Colors.Tan30};
  border-radius: 10px;
`;

export const TableHead = styled(Table.Th)`
  position: sticky;
  top: 0;
  width: 20%;
`;

export const TableData = styled(Table.Td)`
  overflow-wrap: anywhere;
  width: 20%;
`;

// CsvDropBox ///////////////////////////////////////////////////
export const FileUploadInput = styled.input`
  visibility: hidden;
  display: none;
`;
