import { useTranslation } from 'react-i18next';
import { Table } from '@robinpowered/design-system';
import { NewUserDisplayData } from '../../../types';
import { UsersTableWrap, TableHead, TableData } from '../../styles';

const buildUserRows = (usersList: Array<NewUserDisplayData>) => {
  return usersList.map((user) => {
    return (
      <Table.Tr key={user.id}>
        <TableData>{user.name}</TableData>
        <TableData>{user.email}</TableData>
        <TableData>{user.department}</TableData>
        <TableData>{user.title}</TableData>
        <TableData>{user.manager}</TableData>
      </Table.Tr>
    );
  });
};

interface Props {
  usersList: Array<NewUserDisplayData>;
}

const UserListTable = (props: Props): JSX.Element => {
  const { t } = useTranslation('csv_upload');

  return (
    <UsersTableWrap>
      <Table>
        <thead>
          <Table.Tr>
            <TableHead>{t('users_list.table.columns.full_name')}</TableHead>
            <TableHead>{t('users_list.table.columns.email')}</TableHead>
            <TableHead>{t('users_list.table.columns.department')}</TableHead>
            <TableHead>{t('users_list.table.columns.title')}</TableHead>
            <TableHead>{t('users_list.table.columns.manager_email')}</TableHead>
          </Table.Tr>
        </thead>
        <tbody>{buildUserRows(props.usersList)}</tbody>
      </Table>
    </UsersTableWrap>
  );
};

export default UserListTable;
