/* eslint-disable @typescript-eslint/no-explicit-any */
import { useUserAccess } from 'hooks';
import { useEffect } from 'react';
import { useAuthContext } from '../contexts';

const APP_ID =
  process.env.REACT_APP_ROBIN_ENV === 'production'
    ? 'AP-DRJJ6AZGBVWT-2'
    : 'AP-DRJJ6AZGBVWT-2-2';

export const useGainsight = (): void => {
  const { currentUser, currentOrg, loading, error } = useAuthContext();
  const { canManagePlaces } = useUserAccess();
  const withEnv = (str: string | undefined | null) =>
    process.env.REACT_APP_ROBIN_ENV !== 'production' ? `${str}-staging` : str;

  const injectExternalScript = async (src: string) => {
    const loadScript = () =>
      new Promise<void>((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.id = 'gainsight';
        script.onload = () => resolve();
        script.onerror = () => reject();
        document.querySelector('body')?.appendChild(script);
      });

    await loadScript();
  };

  useEffect(() => {
    if (!loading && !error) {
      // Prevent potential subsequent loads and inits
      if (document.querySelector('#gainsight') === null) {
        const src = `https://web-sdk.aptrinsic.com/api/aptrinsic.js?a=${APP_ID}`;
        const i = 'aptrinsic';
        const kvWindow = window as { [key: string]: any };

        kvWindow[i] = function (...args: any[]) {
          (kvWindow[i].q = kvWindow[i].q || []).push(args);
        };
        kvWindow[i].p = APP_ID;
        injectExternalScript(src);
      }

      if (!currentUser || !currentOrg || !(window as any).aptrinsic) {
        return;
      }

      (window as any).aptrinsic(
        'identify',
        {
          id: withEnv(currentUser.id),
          email: currentUser.primaryEmail
            ? withEnv(currentUser.primaryEmail.email)
            : null,
          UserType: canManagePlaces ? 'Admin' : 'Non Admin',
          FullName: withEnv(currentUser.name),
        },
        {
          id: withEnv(currentOrg.id),
          name: withEnv(currentOrg.name),
        }
      );
    }
  }, [loading, error, currentUser, currentOrg, canManagePlaces]);
};
