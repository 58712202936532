/**
 * Converts array into printable user facing version
 * ['a', 'b', 'c'] => 'a, b, & c'
 */
export const printList = (list: Array<string>): string => {
  if (list.length <= 1) {
    return list[0];
  }

  const allButLast = list.slice(0, -1).join(', ');

  const result = allButLast.concat(`, & ${list.slice(-1)}`);

  return result;
};
