import { Component } from 'react';
import { Sentry } from 'lib/sentry';
import { withTranslation, WithTranslation } from 'react-i18next';
import { config } from '../config';
import { PageMessage } from './PageMessage';

type State = {
  error: null | Error;
};

export class ErrorBoundaryComponent extends Component<
  WithTranslation<'error'>,
  State
> {
  state: State = {
    error: null,
  };

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error): void {
    if (config.env !== 'development') {
      Sentry.captureException(error);
    }
  }

  render(): React.ReactNode {
    if (!this.state.error) {
      return this.props.children;
    }
    return (
      <PageMessage
        title={this.props.t('generic_error_heading')}
        message={this.props.t('generic_error_message')}
      />
    );
  }
}

export const GlobalErrorBoundary = withTranslation('error')(
  ErrorBoundaryComponent
);
