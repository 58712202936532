import FileConfirmationUi from './components/FileConfirmationUi';

interface Props {
  goToNextView: () => void;
  handleRemoveCsvFile: () => void;
  handleExit: () => void;
  fileName: string | null;
}
/**
 * FileConfirmationContainer
 * - Placeholder for UI logic and state for file confirmation step
 */
const FileConfirmationContainer = (props: Props): JSX.Element => {
  return (
    <FileConfirmationUi
      goToNextView={props.goToNextView}
      handleRemoveCsvFile={props.handleRemoveCsvFile}
      handleExit={props.handleExit}
      fileName={props.fileName}
    />
  );
};

export default FileConfirmationContainer;
