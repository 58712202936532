import { useDrop, DropTargetMonitor, ConnectDropTarget } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { HandleCsvUpload } from '../../../types';

interface Return {
  validFileHovered: boolean;
  drop: ConnectDropTarget;
}

/**
 * useCsvDropArea
 * - *args* - handleCsvUpload - function handling csv drop
 *
 * - *return val* - object holding
 * -- validFileHovered - boolean indicating if a valid file is hovered
 * -- drop - a reference to the drop target to be passed as a ref to the React component
 */
export const useCsvDropArea = ({
  handleCsvUpload,
}: {
  handleCsvUpload: HandleCsvUpload;
}): Return => {
  const [{ canDrop, isOver }, drop] = useDrop(() => {
    return {
      accept: [NativeTypes.FILE],

      drop(item: unknown) {
        interface Item {
          files: Array<File>;
        }

        const file = (item as Item).files[0];
        handleCsvUpload(file);
      },

      collect(monitor: DropTargetMonitor) {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    };
  });
  const validFileHovered = canDrop && isOver;

  return { validFileHovered, drop };
};
