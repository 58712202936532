import { useTranslation } from 'react-i18next';
import { Flex, Button } from '@robinpowered/design-system';
import { NewUserDisplayData } from '../../../types';
import UserListTable from './UserListTable';
import Header from '../../../../../sharedComponents/Header';
import { HandleAddPeople } from '../../../types';
import CancelButton from '../../../../../sharedComponents/CancelButton';
import {
  BackButton,
  SendEmailFlexContainer,
  UserListContainer,
  FooterPadding,
  UserListHeading,
  UserListCheckbox,
  UserListLearnMoreLink,
} from '../../styles';

interface Props {
  usersList: Array<NewUserDisplayData>;
  handleExit: () => void;
  goToPreviousView: () => void;
  handleSendEmailToggle: () => void;
  handleAddPeople: HandleAddPeople;
  sendEmailInvite: boolean;
  formSubmitted: boolean;
}

const UsersListUi = (props: Props): JSX.Element => {
  const { t } = useTranslation('csv_upload');

  return (
    <div>
      <Header>
        <>
          <CancelButton
            variant="tertiary"
            size="default"
            onClick={props.handleExit}
            disabled={props.formSubmitted}
          >
            {t('users_list.header.cancel')}
          </CancelButton>

          <BackButton
            variant="primary"
            size="default"
            onClick={props.goToPreviousView}
            disabled={props.formSubmitted}
          >
            {t('users_list.header.back')}
          </BackButton>

          <Button
            variant="primary"
            size="default"
            onClick={() =>
              props.handleAddPeople({ sendEmailInvite: props.sendEmailInvite })
            }
            isLoading={props.formSubmitted}
          >
            {t('users_list.header.add_people')}
          </Button>
        </>
      </Header>
      <UserListContainer>
        <Flex display="flex" flexDirection="row" justifyContent="space-between">
          <UserListHeading>{t('users_list.table.header')}</UserListHeading>

          {/* TODO: Store link in env var */}
          <UserListLearnMoreLink
            href="https://support.robinpowered.com/hc/en-us/articles/203687424-Invite-your-coworkers"
            target="_blank"
          >
            {t('users_list.table.learn_more_link')}
          </UserListLearnMoreLink>
        </Flex>

        <UserListTable usersList={props.usersList} />

        <SendEmailFlexContainer
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <UserListCheckbox
            checked={props.sendEmailInvite}
            handleClick={props.handleSendEmailToggle}
            id="invitation-via-email"
          />
          <label htmlFor="invitation-via-email">
            {t('users_list.table.toggle_email_invite')}
          </label>
        </SendEmailFlexContainer>
      </UserListContainer>
      <FooterPadding />
    </div>
  );
};

export default UsersListUi;
