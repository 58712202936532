export const CSV_ROW_LIMIT = 500;

export const SYNCHRONOUS_FLOW_CSV_ROW_LIMIT = 150; // Max csv row size to add users synchronously

export const CSV_REQUIRED_FIELDS = ['email'];

export const ACCEPTABLE_FIELDS = [
  'name',
  'email',
  'department',
  'title',
  'manager',
];
