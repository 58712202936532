import axios, { AxiosResponse } from 'axios';
import * as TE from 'fp-ts/lib/TaskEither';
import { config } from '../../config';
import { AddUsersManuallyArgs, AddUsersWithCsvArgs } from '../types/data';
import { generateError, UserUploadError } from '../types/errors';
import { SYNCHRONOUS_FLOW_CSV_ROW_LIMIT } from 'app/utils/constants';

const baseUrl = config.userUploadApiBaseUrl;

export const postUsersManually = async ({
  newUsers,
  sendEmailInvite,
  organizationId,
  apiKey,
}: AddUsersManuallyArgs): Promise<AxiosResponse> => {
  const reqBody = {
    users: newUsers,
    send_invites: sendEmailInvite,
    organizationId: Number(organizationId),
  };

  return await axios({
    baseURL: baseUrl,
    method: 'POST',
    url: '/AddUsers',
    data: reqBody,
    headers: { authorization: apiKey },
  });
};

export const caughtPostUsersManually = (
  args: AddUsersManuallyArgs
): TE.TaskEither<UserUploadError, AxiosResponse> => {
  return TE.tryCatch(
    async () => await postUsersManually(args),
    () => generateError({ type: 'FailedToAddUsersManually' })
  );
};

export const postUsersWithCsv = async ({
  csvFile,
  numOfRows,
  sendEmailInvite,
  organizationId,
  apiKey,
}: AddUsersWithCsvArgs): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append('uploadedFile', csvFile);
  formData.append('send_invites', `${sendEmailInvite}`);
  formData.append('organizationId', `${organizationId}`);

  const url =
    numOfRows > SYNCHRONOUS_FLOW_CSV_ROW_LIMIT
      ? '/AddUsersByCsvAsync'
      : '/AddUsersByCsv';

  return await axios({
    baseURL: baseUrl,
    method: 'POST',
    url,
    data: formData,
    headers: {
      authorization: apiKey,
      'Content-Type': 'multipart/form-data',
      accept: '*/*',
    },
  });
};

export const caughtPostUsersWithCsv = (
  args: AddUsersWithCsvArgs
): TE.TaskEither<UserUploadError, AxiosResponse> => {
  return TE.tryCatch(
    async () => await postUsersWithCsv(args),
    () => generateError({ type: 'FailedToAddUsersWithCsv' })
  );
};

export const failUnsuccessfulRes = (error: UserUploadError) => {
  return (statusCode: number): TE.TaskEither<UserUploadError, number> => {
    return statusCode >= 200 && statusCode < 400
      ? TE.right(statusCode)
      : TE.left(error);
  };
};

export const getStatusCode = (response: AxiosResponse): number =>
  response.status;
