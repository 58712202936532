import { Input } from '@robinpowered/design-system';
import { InputChangeEvent, HandleInputChange } from '../types';

interface Props {
  inputName: string;
  inputVal: string;
  handleInputChange: HandleInputChange;
  handleBlur?: () => void;
  rowIdx: number;
}

const FormInput = ({
  inputName,
  inputVal,
  handleInputChange,
  handleBlur,
  rowIdx,
}: Props): JSX.Element => {
  return (
    <Input
      name={inputName}
      value={inputVal}
      onChange={(event: InputChangeEvent) =>
        handleInputChange({ event, rowIdx })
      }
      onBlur={handleBlur}
      mr="15px"
    />
  );
};

export default FormInput;
