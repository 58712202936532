import { Link } from '@robinpowered/design-system';
import { useTranslation } from 'react-i18next';

const DownloadCsvTemplateLink = (): JSX.Element => {
  const { t } = useTranslation('csv_upload');

  return (
    <Link
      href={`${process.env.PUBLIC_URL}/static/media/template.csv`}
      target="_blank"
      download
    >
      {t('upload_form.csv_drop_card.csv_template_link')}
    </Link>
  );
};

export default DownloadCsvTemplateLink;
