import { useTranslation } from 'react-i18next';
import { Flex, Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';

const HeaderContainer = styled.header`
  display: block;
  position: relative;
  background: ${Colors.Gray100};
  color: ${Colors.Tan30};
  margin-bottom: 50px;
  padding: 20px;
  font-weight: 500;
`;

const HeaderText = styled.h1`
  text-align: center;
  position: absolute;
  left: calc(50% - 65px);
`;

interface Props {
  children: JSX.Element;
}

const Header = (props: Props): JSX.Element => {
  const { t } = useTranslation('common');

  return (
    <HeaderContainer>
      <Flex
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <HeaderText>{t('header.main')}</HeaderText>
        {props.children}
      </Flex>
    </HeaderContainer>
  );
};

export default Header;
