import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import UploadFormUi from './components/UploadFormUi';
import { convertCsvToUsersList } from '../../../../utils/convertCsvToUsersList';
import * as E from 'fp-ts/lib/Either';
import { HandleSuccessfulCsvUploadArgs } from '../../types';
import { getUserErrorMessaging } from './utils/getUserErrorMessaging';

export interface State {
  errorMessage: string | null;
}

interface Props {
  goToNextView: () => void;
  handleExit: () => void;
  handleSuccessfulCsvUpload: (args: HandleSuccessfulCsvUploadArgs) => void;
}

/**
 * UploadFormContainer
 * - handles UI logic for CSV upload form
 */
const UploadFormContainer = (props: Props): JSX.Element => {
  const initialState: State = {
    errorMessage: null,
  };
  const [state, setState] = useState(initialState);

  /**
   * handleCsvUpload
   * - parses and validates CSV file
   * - If unsuccessful triggers an error message
   * - If successful triggers storing user data in flow state
   */
  const handleCsvUpload = async (file: File) => {
    const parsedCsvData = await convertCsvToUsersList(file);

    if (E.isLeft(parsedCsvData)) {
      const errorMessage = getUserErrorMessaging(parsedCsvData.left);
      setState({ ...state, errorMessage });
    } else {
      const { usersList, fileName } = parsedCsvData.right;
      props.handleSuccessfulCsvUpload({ usersList, fileName, csvFile: file });
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <UploadFormUi
        goToNextView={props.goToNextView}
        handleExit={props.handleExit}
        handleCsvUpload={handleCsvUpload}
        errorMessage={state.errorMessage}
      />
    </DndProvider>
  );
};

export default UploadFormContainer;
