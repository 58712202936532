import { ReactNode } from 'react';
import { Heading05, Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';

interface Props {
  headerContent: ReactNode;
  children: ReactNode;
}

const CardContainer = styled.section`
  background: ${Colors.White0};
  border-radius: 14px;
  margin: 20px;
  border: 1px solid ${Colors.Tan70};
  overflow: hidden;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
`;

const CardHeader = styled(Heading05)`
  background-color: ${Colors.Tan5};
  padding: 17px;
  border-bottom: 1px solid ${Colors.Tan70};
`;

const CardBody = styled.div`
  padding: 17px;
`;

const CardWithHeader = ({ headerContent, children }: Props): JSX.Element => {
  return (
    <CardContainer>
      <CardHeader>{headerContent}</CardHeader>
      <CardBody>{children}</CardBody>
    </CardContainer>
  );
};

export default CardWithHeader;
