import { useTranslation } from 'react-i18next';
import { Button } from '@robinpowered/design-system';
import CsvDropBox from './CsvDropBox';
import Header from '../../../../../sharedComponents/Header';
import CsvUploadCard from '../../../sharedComponents/CsvUploadCard/CsvUploadCard';
import CsvUploadInfo from '../../../sharedComponents/CsvUploadInfo/CsvUploadInfo';
import CancelButton from '../../../../../sharedComponents/CancelButton';
import {
  CsvDropBoxFlexContainer,
  CsvInfoFlexContainer,
  CsvFlexContainer,
} from '../../styles';

interface Props {
  goToNextView: () => void;
  handleExit: () => void;
  handleCsvUpload: (file: File) => void;
  errorMessage: string | null;
}

const UploadFormUi = (props: Props): JSX.Element => {
  const { t } = useTranslation('csv_upload');

  return (
    <>
      <Header>
        <>
          <CancelButton
            variant="tertiary"
            size="default"
            onClick={props.handleExit}
          >
            {t('upload_form.header.cancel')}
          </CancelButton>
          <Button
            variant="primary"
            size="default"
            disabled={true}
            onClick={props.goToNextView}
          >
            {t('upload_form.header.next')}
          </Button>
        </>
      </Header>

      <CsvFlexContainer>
        <CsvDropBoxFlexContainer>
          <CsvUploadCard>
            <CsvDropBox
              handleCsvUpload={props.handleCsvUpload}
              errorExists={Boolean(props.errorMessage)}
            />

            {props.errorMessage && <span>{props.errorMessage}</span>}
          </CsvUploadCard>
        </CsvDropBoxFlexContainer>

        <CsvInfoFlexContainer>
          <CsvUploadInfo />
        </CsvInfoFlexContainer>
      </CsvFlexContainer>
    </>
  );
};

export default UploadFormUi;
