import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { config } from '../config';

const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || config.env;

Sentry.init({
  enabled: SENTRY_ENV !== 'development',
  environment: SENTRY_ENV,
  dsn: config.sentryDsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
});

export { Sentry };
