import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropBox,
  DropBoxContent,
  FileInputLabel,
  FileUploadInput,
} from '../../styles';
import { HandleCsvUpload } from '../../../types';
import { useCsvDropArea } from '../utils/useCsvDropArea';

interface FileInputProps {
  children: ReactNode;
  handleCsvUpload: HandleCsvUpload;
}

const FileInput = ({ children, handleCsvUpload }: FileInputProps) => {
  return (
    <span>
      <label htmlFor="file-upload">
        <FileInputLabel>{children}</FileInputLabel>
      </label>
      <FileUploadInput
        type="file"
        id="file-upload"
        name="file-upload"
        onChange={(e) => e.target.files && handleCsvUpload(e.target.files[0])}
      />
    </span>
  );
};

interface Props {
  handleCsvUpload: HandleCsvUpload;
  errorExists: boolean;
}

const CsvDropBox = ({ handleCsvUpload, errorExists }: Props): JSX.Element => {
  const { t } = useTranslation('csv_upload');
  const { validFileHovered, drop } = useCsvDropArea({ handleCsvUpload });

  return (
    <DropBox ref={drop} hovered={validFileHovered} errorExists={errorExists}>
      <DropBoxContent>
        <span>{t('upload_form.csv_drop_card.instructions.first_line')}</span>
        <span>
          {t('upload_form.csv_drop_card.instructions.second_line.before_link')}
          <FileInput handleCsvUpload={handleCsvUpload}>
            {t('upload_form.csv_drop_card.instructions.second_line.link')}
          </FileInput>
          {t('upload_form.csv_drop_card.instructions.second_line.after_link')}
        </span>
      </DropBoxContent>
    </DropBox>
  );
};

export default CsvDropBox;
