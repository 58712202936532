import { pipe } from 'fp-ts/lib/function';
import * as E from 'fp-ts/lib/Either';
import * as TE from 'fp-ts/lib/TaskEither';
import { AddUsersManuallyArgs, AddUsersWithCsvArgs } from '../types/data';
import {
  caughtPostUsersManually,
  failUnsuccessfulRes,
  getStatusCode,
  caughtPostUsersWithCsv,
} from './helpers';
import { UserUploadError, generateError } from '../types/errors';

const User = {
  // Sends new user data in a json array
  // Used for the manual upload path
  addUsersManually: async ({
    newUsers,
    sendEmailInvite,
    organizationId,
    apiKey,
  }: AddUsersManuallyArgs): Promise<E.Either<UserUploadError, number>> => {
    const error = generateError({ type: 'FailedToAddUsersManually' });

    return pipe(
      caughtPostUsersManually({
        newUsers,
        sendEmailInvite,
        organizationId,
        apiKey,
      }),
      TE.map(getStatusCode),
      TE.chain(failUnsuccessfulRes(error))
    )();
  },

  // Sends user data in CSV file
  // Used for the CSV upload path
  addUsersWithCsv: async ({
    csvFile,
    numOfRows,
    sendEmailInvite,
    organizationId,
    apiKey,
  }: AddUsersWithCsvArgs): Promise<E.Either<UserUploadError, number>> => {
    const error = generateError({ type: 'FailedToAddUsersWithCsv' });

    return pipe(
      caughtPostUsersWithCsv({
        csvFile,
        numOfRows,
        sendEmailInvite,
        organizationId,
        apiKey,
      }),
      TE.map(getStatusCode),
      TE.chain(failUnsuccessfulRes(error))
    )();
  },
};

export default User;
