export type ErrorBase<T extends string> = { __type: T };

export type CsvFailedToParse = ErrorBase<'CsvFailedToParse'>;

export type InvalidFileType = ErrorBase<'InvalidFileType'>;

export type NoRowsInCsv = ErrorBase<'NoRowsInCsv'>;

export type TooManyRowsInCsv = ErrorBase<'TooManyRowsInCsv'>;

export type MissingRequiredField = {
  missingFields: Array<string>;
} & ErrorBase<'MissingRequiredField'>;

export type FailedToAddUsersManually = ErrorBase<'FailedToAddUsersManually'>;
export type FailedToAddUsersWithCsv = ErrorBase<'FailedToAddUsersWithCsv'>;

export type UserUploadError =
  | CsvFailedToParse
  | InvalidFileType
  | NoRowsInCsv
  | TooManyRowsInCsv
  | MissingRequiredField
  | FailedToAddUsersManually
  | FailedToAddUsersWithCsv;

type AdditionalParams<T extends UserUploadError> = Record<
  keyof Omit<T, '__type'>,
  T
>;

type ErrorType<T extends UserUploadError> = {
  [P in keyof T]: P extends '__type' ? T[P] : never;
}[keyof T];

type GenerateErrorArgs<T extends UserUploadError> = {
  type: ErrorType<T>; // One of the existing error types of a UserUploadError
  extraParams?: AdditionalParams<T>; // An object. None of the keys may be '__type'.
};

export const generateError = <T extends UserUploadError>({
  type,
  extraParams,
}: GenerateErrorArgs<T>): T => ({ __type: type, ...extraParams } as T);

// Example error creation /////////////////////////////////
// generateError({
//   type: 'CsvFailedToParse',
//   extraParams: {
//     id: '1234',
//     extraInfo: 'asdfasdf'
//   }
// })
