import { Flex, Button, Colors, Heading05 } from '@robinpowered/design-system';
import styled from '@emotion/styled';
import InfoImg from '@robinpowered/icons/InfoSolid';

// ManualUploadUi ///////////////////////////////////////////
export const FormFlexContainer = styled.div`
  width: 65%;
`;
export const EmailInviteFlexContainer = styled.div`
  width: 35%;
`;

// ManualUploadForm /////////////////////////////////////////
export const CancelXImg = styled.img`
  position: relative;
  top: 14px;
  cursor: pointer;
  width: 12px;
  height: 12px;
`;

export const CancelImgPlaceholder = styled.span`
  width: 24px;
  height: 12px;
`;

export const AddPersonButton = styled(Button)`
  color: ${Colors.Maroon100};
  margin-top: 20px;
  margin-bottom: 12px;
`;

// EmailInvitesToggle ///////////////////////////////////////
export const InfoBox = styled(Flex)`
  display: flex;
  align-items: flex-start;
  background: ${Colors.Tan5};
  border-radius: 16px;
  padding: 16px;
`;

export const InfoBoxContent = styled(Flex)`
  margin-left: 5px;
`;

export const EmailInvitesToggleRow = styled(Flex)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const StyledInfoIconComp = (): JSX.Element => (
  <div>
    {/* div needed for sizing of icon */}
    <InfoImg size={16} color={Colors.Gray80} />
  </div>
);

export const EmailInvitesHeader = styled(Heading05)`
  margin-bottom: 8px;
`;

// EmailInput ///////////////////////////////////////////////
export const EmailError = styled.span`
  color: ${Colors.RedProduct};
  margin-top: 10px;
`;

// ManualUploadUi ///////////////////////////////////////////
export const ManualUploadCardContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
`;

export const LabelRow = styled(Flex)`
  display: flex;
  flex-direction: row;
`;

export const Label = styled.label`
  display: flex;
  flex-grow: 1;
`;

// ManualUploadForm //////////////////////////////////////////
export const FormRowContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 16px;
`;
