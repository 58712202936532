import { useTranslation } from 'react-i18next';
import { Body06 } from '@robinpowered/design-system';
import { CsvUploadInfoContainer, CsvUploadInfoHeading } from './styles';
import { CSV_ROW_LIMIT } from '../../../../utils/constants';

const CsvUploadInfo = (): JSX.Element => {
  const { t } = useTranslation('csv_upload');

  return (
    <CsvUploadInfoContainer>
      <CsvUploadInfoHeading>
        {t('upload_form.info_card.header')}
      </CsvUploadInfoHeading>
      <Body06>
        {t('upload_form.info_card.body_before')}
        {CSV_ROW_LIMIT}
        {t('upload_form.info_card.body_after')}
      </Body06>
    </CsvUploadInfoContainer>
  );
};

export default CsvUploadInfo;
