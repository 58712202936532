import { ReactNode } from 'react';
import { Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';

const CardContainer = styled.section`
  background: ${Colors.White0};
  border-radius: 14px;
  margin: 20px;
  border: 1px solid ${Colors.Tan70};
  overflow: hidden;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
`;

const CardBody = styled.div`
  padding: 17px;
`;

const Card = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <CardContainer>
      <CardBody>{children}</CardBody>
    </CardContainer>
  );
};

export default Card;
