import { pipe } from 'fp-ts/lib/function';
import { Either } from 'fp-ts/lib/Either';
import { map, chain } from 'fp-ts/lib/TaskEither';
import {
  caughtParseCsv,
  checkFileExtension,
  checkNoRows,
  checkTooManyRows,
  checkFieldNames,
  getUsersFromCsvData,
  checkInvalidEmails,
  checkDuplicateEmails,
  packageCsvData,
} from './helpers';
import { UserUploadError } from '../../types/errors';
import { NewUser, CsvDataForDisplay } from '../../types/data';

export const convertCsvToUsersList = async (
  file: File
): Promise<Either<UserUploadError, CsvDataForDisplay>> => {
  return pipe(
    file,
    checkFileExtension,
    chain(caughtParseCsv),
    chain(checkNoRows),
    chain(checkTooManyRows),
    chain(checkFieldNames),
    map(getUsersFromCsvData),
    map(checkInvalidEmails),
    map(checkDuplicateEmails),
    map((newUsers: Array<NewUser>) =>
      packageCsvData({ newUsers, fileName: file.name })
    )
  )();
};
