import { PageMessage, RobinLoader } from 'components';
import { config } from '../../config';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const { env, dashboardUrl } = config;

const RedirectToDashboardHomepage: React.FC<{
  errorMessage?: string | null;
}> = ({ errorMessage }) => {
  const { t } = useTranslation('error');
  useEffect(() => {
    if (env !== 'development') {
      window.location.href = dashboardUrl as string;
    }
  }, []);
  if (env === 'development') {
    const message: string = errorMessage ?? t('404');
    return <PageMessage title={message} />;
  }

  return <RobinLoader />;
};

export default RedirectToDashboardHomepage;
