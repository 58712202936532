/** isEmailValid
 * - Ensures email is in proper format for an email.
 *
 * *args* - email (string) - an email address
 *
 * *return val* - boolean indicating if email is valid
 */
export const isEmailValid = (email: string): boolean => {
  const emailPattern = /^[^@]+@[[\]a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

  return emailPattern.test(email);
};
