import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { NewUserDisplayData } from './types';
import { HandleSuccessfulCsvUploadArgs } from './types';
import UploadForm from './steps/UploadForm';
import FileConfirmation from './steps/FileConfirmation';
import UsersList from './steps/UsersList';
import { useDashboardNavigateUrl } from 'hooks';

interface State {
  stepIdx: number;
  usersList: Array<NewUserDisplayData>;
  fileName: string | null;
  csvFile: File | null;
}

/**
 * CsvUploadFlowContainer
 * - Handles flow-wide logic and state for the CSV Upload flow
 * - Logic for individual steps can be found in the container for that step
 */
const CsvUploadFlowContainer = (): JSX.Element => {
  const initialState: State = {
    stepIdx: 0,
    usersList: [],
    fileName: null,
    csvFile: null,
  };
  const [state, setState] = useState(initialState);
  const dashboardNavigateUrl = useDashboardNavigateUrl();

  const goToNextView = () => {
    state.stepIdx < lastStepIdx &&
      setState({ ...state, stepIdx: state.stepIdx + 1 });
  };

  const goToPreviousView = () => {
    state.stepIdx > 0 && setState({ ...state, stepIdx: state.stepIdx - 1 });
  };

  /**
   * handleExit
   * - triggers redirect when user exits the flow
   */
  const handleExit = () =>
    window.location.assign(
      `${dashboardNavigateUrl}?addPeopleStatus=inProgress`
    );

  /**
   * handleSuccessfulCsvUpload
   * - triggered when CSV successfully parses and validates after upload to browser
   * - Adds usersList to state of the flow
   * - navigates to next step
   */
  const handleSuccessfulCsvUpload = ({
    usersList,
    fileName,
    csvFile,
  }: HandleSuccessfulCsvUploadArgs) => {
    const usersListWithIds = usersList.map((user) => ({
      ...user,
      id: uuidv4(),
    }));

    setState({
      ...state,
      usersList: usersListWithIds,
      fileName,
      csvFile,
      stepIdx: state.stepIdx + 1,
    });
  };

  /**
   * handleRemoveCsvFile
   * - removes usersList from state of the flow
   * - navigates to previous step
   */
  const handleRemoveCsvFile = () => {
    setState({
      ...state,
      usersList: [],
      fileName: null,
      csvFile: null,
      stepIdx: state.stepIdx - 1,
    });
  };

  const steps = [
    <UploadForm
      key={0}
      goToNextView={goToNextView}
      handleExit={handleExit}
      handleSuccessfulCsvUpload={handleSuccessfulCsvUpload}
    />,
    <FileConfirmation
      key={1}
      goToNextView={goToNextView}
      handleRemoveCsvFile={handleRemoveCsvFile}
      handleExit={handleExit}
      fileName={state.fileName}
    />,
    <UsersList
      key={2}
      handleExit={handleExit}
      goToPreviousView={goToPreviousView}
      usersList={state.usersList}
      csvFile={state.csvFile}
    />,
  ];

  const lastStepIdx = steps.length - 1;

  return steps[state.stepIdx];
};

export default CsvUploadFlowContainer;
