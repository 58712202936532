import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import DownloadCsvTemplateLink from '../../../../sharedComponents/DownloadCsvTemplateLink';
import { CardContainer, CardContent, CardHeader } from './styles';

const CsvUploadCard = ({ children }: { children: ReactNode }): JSX.Element => {
  const { t } = useTranslation('csv_upload');

  return (
    <CardContainer>
      <CardHeader>{t('upload_form.csv_drop_card.header')}</CardHeader>
      <DownloadCsvTemplateLink />
      <CardContent>{children}</CardContent>
    </CardContainer>
  );
};

export default CsvUploadCard;
