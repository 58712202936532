declare global {
  interface Window {
    NODE_ENV: 'development' | 'production' | 'test';
    REACT_APP_DASHBOARD_URL: string;
    REACT_APP_USER_UPLOAD_API_BASE_URL: string;
    REACT_APP_COOKIE_NAME: string;
    REACT_APP_AUTH_COOKIE_NAME: string;
    REACT_APP_SENTRY_DSN: string;
    REACT_APP_GQL_URL: string;
    REACT_APP_DASHBOARD_API_URL: string;
    REACT_APP_NAME: string;
    REACT_APP_VERSION: string;
  }
}

type Config = {
  env: 'development' | 'production' | 'test';
  userUploadApiBaseUrl: string;
  sessionCookieName: string;
  authSessionCookieName: string;
  dashboardUrl: string;
  sentryDsn: string;
  gqlUrl: string;
  dashBoardApiUrl: string;
  appName: string;
  appVersion: string;
};

export const config: Config = {
  env: window.NODE_ENV,
  userUploadApiBaseUrl: window.REACT_APP_USER_UPLOAD_API_BASE_URL,
  dashboardUrl: window.REACT_APP_DASHBOARD_URL,
  sessionCookieName: window.REACT_APP_COOKIE_NAME,
  authSessionCookieName: window.REACT_APP_AUTH_COOKIE_NAME,
  sentryDsn: window.REACT_APP_SENTRY_DSN,
  gqlUrl: window.REACT_APP_GQL_URL,
  dashBoardApiUrl: window.REACT_APP_DASHBOARD_API_URL,
  appName: window.REACT_APP_NAME,
  appVersion: window.REACT_APP_VERSION,
};

if (
  !window.REACT_APP_DASHBOARD_URL ||
  !window.REACT_APP_USER_UPLOAD_API_BASE_URL ||
  !window.REACT_APP_COOKIE_NAME ||
  !window.REACT_APP_AUTH_COOKIE_NAME ||
  !window.REACT_APP_SENTRY_DSN ||
  !window.REACT_APP_GQL_URL ||
  !window.REACT_APP_DASHBOARD_API_URL ||
  !window.REACT_APP_NAME ||
  !window.REACT_APP_VERSION
) {
  throw new Error('envars not defined correctly');
}

/* eslint-disable no-console */
if (config.env === 'development') {
  console.groupCollapsed('Started app with config');
  console.table(config);
  console.groupEnd();
}
/* eslint-enable no-console */
