import { Heading02, Colors } from '@robinpowered/design-system';
import styled from '@emotion/styled';

export const CardContainer = styled.section`
  background: ${Colors.White0};
  border-radius: 16px;
  border: 1px solid ${Colors.Tan70};
  padding: 24px;
`;

export const CardContent = styled.div`
  margin-top: 18px;
`;

export const CardHeader = styled(Heading02)`
  margin-bottom: 5px;
`;
